































import { Vue, Component, Watch } from 'vue-property-decorator';
import * as firebase from 'firebase';
import { EventBus } from '@/config/config';

@Component
export default class SimpleLayout extends Vue {
  public appBanner: boolean = false;
  public mounted() {
    if (this.$cookies.get('PoingAppBanner') === 'Y') {
      this.appBanner = false;
    } else {
      if (
        this.$route.name !== 'place' &&
        this.$route.name !== 'paymentResult'
      ) {
        this.appBanner = false;
      } else if (this.isApp) {
        this.appBanner = false;
      } else {
        this.appBanner = true;
      }
    }
		if (this.$q.platform.is.desktop) {
			this.appBanner = false;
		}
  }
  public moveAppStore() {
		window.location.href = 'https://poingapp.page.link/installApps';
    this.eventAppDownChoice('yes');
	}
  public appCloseClick() {
		const date = new Date();
		date.setHours(23, 59, 59, 999);
		this.$cookies.set('PoingAppBanner', 'Y', date);
		this.appBanner = false;
    this.eventAppDownChoice('no');
    EventBus.$emit('PoingAppBannerClose');
	}
  public eventAppDownChoice(data) {
      const eventName = 'app_download';
      let platform: string = '';
      if (this.$q.platform.is.platform === 'android') {
        platform = 'ANDROID';
      } else if (
        this.$q.platform.is.platform === 'iphone' ||
        this.$q.platform.is.platform === 'ios' ||
        this.$q.platform.is.platform === 'ipad'
      ) {
        platform = 'IOS';
      }
      const eventParams: any = {
        choice: data,
        platform: platform,
        type: 'mobile-web-top'
      };
      firebase.analytics().logEvent(eventName, eventParams);
    }

  @Watch('$route')
  public routeUpdate() {
    if (this.$cookies.get('PoingAppBanner') === 'Y') {
      this.appBanner = false;
    } else {
      if (
        this.$route.name !== 'place' &&
        this.$route.name !== 'paymentResult'
      ) {
        this.appBanner = false;
      } else if (this.isApp) {
        this.appBanner = false;
      } else {
        this.appBanner = true;
      }
    }
  }
  get isApp() {
    if (this.$route.query._from) return true;
    else return false;
  }
}
